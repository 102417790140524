import { logger as baseLogger, ScopedLogger } from '@getvim/vim-connect-logger';
import { retry, withTimeout } from '@vim/promise';
import axios from 'axios';
import Rox from 'rox-browser';
import { Team } from '@getvim/vim-code-ownership/team';
import {
  TIMEOUT_INITIALIZING_ROLLOUT,
  FlagContext,
  FlagOptions,
  ClientOptions,
  ROLLOUT_PING_TIMEOUT_IN_MS,
  LOG_INTERVAL,
} from './types';

/**
 * @deprecated Use `import { Team } from '@getvim/vim-code-ownership/team'` instead.
 */
// eslint-disable-next-line unicorn/prefer-export-from
export { Team };

const APP_ENV = window.$vim_environment?.APP_ENV;

const envToProxy = async (env?: typeof APP_ENV) => {
  let environment = env;
  if (!env) {
    environment = (await axios.get('/api/appEnv')).data?.appEnv;
  }
  if (!environment) {
    return 'rollout-proxy.getvim.com'; // go through prod if api fallback fails
  }
  switch (environment) {
    case 'local':
    case 'dev':
      return 'rollout-proxy.dev.getvim.com';
    case 'staging':
    case 'demo':
      return `rollout-proxy.${environment}.getvim.com`;
    case 'prod':
      return 'rollout-proxy.getvim.com';
    default:
      return 'rollout-proxy.getvim.com';
  }
};

function getDefaultRolloutKey() {
  switch (getAppEnv()) {
    case 'local':
    case 'dev':
      return '5bfe9b37fdb92508d018e718';
    case 'staging':
      return '5d1227be0d242b1c8ce4558b';
    case 'demo':
      return '5d78fd679a687a510dd42636';
    case 'prod':
    default:
      return '5bed72e46a25e63420fce3cd';
  }
}

function getAppEnv(): string {
  const appEnv = window?.$vim_environment?.APP_ENV;
  const url = window?.location?.href;
  if (appEnv) {
    return appEnv;
  }

  if (url?.includes('prodvim') || url?.includes('getvim')) {
    return 'prod';
  } else if (url?.includes('.demo.')) {
    return 'demo';
  } else if (url?.includes('.staging.')) {
    return 'staging';
  } else if (url?.includes('.dev.')) {
    return 'dev';
  } else if (url?.includes('localhost')) {
    return 'local';
  }
  return '';
}

const getRolloutKey = (rolloutKey?: string) =>
  rolloutKey || window.$vim_environment?.rollout__key || getDefaultRolloutKey();

export class FeatureFlagsClient {
  private static isReady?: Promise<void>;
  private static rolloutKey: string;
  private static wiRolloutKey?: string;
  private team?: Team;
  private namespace?: string;
  private defaultContext?: FlagContext;
  private static overrideValues: Map<string, any> = FeatureFlagsClient.initOverrides();
  private static cachedValues: Map<string, any> = new Map<string, any>();
  private static lastFetchedFromRollout: any;
  private static logger = baseLogger.scope('Feature Flags Client');
  private static isRolloutAccessible: boolean = false;
  // Store the time the error log was last triggered per feature flag.
  private static flagErrorLogTimestamps: Map<string, number> = new Map();

  constructor({ rolloutKey: rolloutKeyParam, team, namespace, defaultContext }: ClientOptions) {
    const rolloutKey = getRolloutKey(rolloutKeyParam);

    if (!FeatureFlagsClient.isReady && !FeatureFlagsClient.rolloutKey) {
      FeatureFlagsClient.rolloutKey = rolloutKey;
    } else if (rolloutKey !== FeatureFlagsClient.rolloutKey) {
      FeatureFlagsClient.log(
        'error',
        'New rollout key is not respected as another client was created with another rollout key! Creating the client with current rollout key.',
        { rolloutKey: FeatureFlagsClient.rolloutKey, newRolloutKey: rolloutKey },
      );
    }
    this.team = team;
    this.namespace = namespace;
    this.defaultContext = defaultContext;
  }

  static initOverrides() {
    try {
      const fromLocalStorage = window.localStorage.getItem('featureFlagsOverride') ?? '{}';
      const overrides = JSON.parse(fromLocalStorage);
      return new Map(Object.entries(overrides));
    } catch (error) {
      return new Map();
    }
  }

  static async initRollout(resolve: (value: void | PromiseLike<void>) => void) {
    let rolloutKeyAPI = FeatureFlagsClient.rolloutKey;
    if (!rolloutKeyAPI) {
      FeatureFlagsClient.log('info', 'Got empty rolloutKey, using WI rolloutKey...', {
        rolloutKeyAPI,
      });
      rolloutKeyAPI = (await axios.get('/api/rolloutConfig')).data?.key;
      FeatureFlagsClient.wiRolloutKey = rolloutKeyAPI;
    }

    const proxyHost = await envToProxy(APP_ENV);

    const useProxy = await this.shouldUseProxy(this.rolloutKey);
    await Rox.setup(rolloutKeyAPI, {
      fetchIntervalInSec: 600,
      analytics: {
        flushAt: 50,
        flushInterval: 10000,
      },
      configurationFetchedHandler: (result) => {
        // omit "clientData" which cloudflare will reject anyway
        const { clientData, ...response } = result as unknown as any;
        FeatureFlagsClient.log('info', 'Rollout configuration fetched', { response });
        this.lastFetchedFromRollout = response;
      },
      ...(useProxy
        ? ({
            proxy: {
              host: proxyHost,
              protocol: 'https',
            },
          } as any) // must do as any
        : {}),
    });
    this.log('info', 'Initialized rollout successfully', {
      rolloutKeyAPI,
      rolloutKey: this.rolloutKey,
      wiRolloutKey: this.wiRolloutKey,
    });
    resolve();
  }

  public static init() {
    FeatureFlagsClient.isReady = new Promise(async (resolve, reject) => {
      try {
        await retry(
          withTimeout((_) => FeatureFlagsClient.initRollout(resolve), TIMEOUT_INITIALIZING_ROLLOUT),
          { minTimeout: 200, retries: 3 },
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  public updateDefaultContext(newContext: FlagContext) {
    this.defaultContext = {
      ...(this.defaultContext ?? {}),
      ...newContext,
    };
  }

  public setDefaultContext(newContext: FlagContext) {
    this.defaultContext = newContext;
  }

  private logError(error: any, flagOptions: FlagOptions<any>) {
    const withPrefixFlag: string = this.getFlagNameWithPrefix(flagOptions);
    const currentTime: number = Date.now();
    const lastLogTime: number | undefined =
      FeatureFlagsClient.flagErrorLogTimestamps.get(withPrefixFlag);
    if (!lastLogTime || currentTime - lastLogTime >= LOG_INTERVAL) {
      FeatureFlagsClient.log(
        'error',
        'An error occurred while trying to fetch featureFlag, returning default value.',
        {
          flagOptions,
          rolloutKey: FeatureFlagsClient.rolloutKey,
          wiRolloutKey: FeatureFlagsClient.wiRolloutKey,
          error,
          isRolloutAccessible: FeatureFlagsClient.isRolloutAccessible,
          statusFromRollout: FeatureFlagsClient.lastFetchedFromRollout?.fetcherStatus,
          errorDetails: FeatureFlagsClient.lastFetchedFromRollout?.errorDetails,
          vimEnvironment: window.$vim_environment,
        },
      );
      FeatureFlagsClient.flagErrorLogTimestamps.set(withPrefixFlag, currentTime);
    }
  }

  public getFlagNameWithPrefix(flagOptions: FlagOptions<any>): string {
    const { flagName, team, namespace } = flagOptions;
    return [
      flagOptions.hasOwnProperty('namespace') ? namespace : this.namespace,
      flagOptions.hasOwnProperty('team') ? team : this.team,
      flagName,
    ]
      .filter(Boolean)
      .join('.');
  }

  public async getFlag(flagOptions: FlagOptions<number>): Promise<number>;
  public async getFlag(flagOptions: FlagOptions<string>): Promise<string>;
  public async getFlag(flagOptions: FlagOptions<boolean>): Promise<boolean>;
  public async getFlag(flagOptions: FlagOptions<any>): Promise<any> {
    try {
      if (!FeatureFlagsClient.isReady) {
        FeatureFlagsClient.init();
      }
      await FeatureFlagsClient.isReady;
      const { flagName, flagContext, defaultValue, isSessionFlag } = flagOptions;
      let cachedValue;

      const withPrefixFlag: string = this.getFlagNameWithPrefix(flagOptions);

      const flagContextWithVimVersion = {
        vimVersion: window.$vim_environment?.vim_version ?? window.$vim_build_env?.vim_version,
        ...(this.defaultContext ?? {}),
        ...flagContext,
      };
      const overrideValue = FeatureFlagsClient.overrideValues.get(withPrefixFlag);
      if (overrideValue !== undefined) {
        FeatureFlagsClient.log('debug', 'Feature Flags - Used override flag value', {
          flagName: withPrefixFlag,
          flagValue: overrideValue,
          cachedValue: cachedValue,
          flagOptions: {
            namespace: this.namespace,
            team: this.team,
            ...flagContextWithVimVersion,
          },
        });
        return overrideValue;
      }
      let value;
      // This will check if the flag is set for session and if "false" return current flag value
      if (!isSessionFlag) {
        value = this.returnFlagByType(
          value,
          defaultValue,
          withPrefixFlag,
          flagContextWithVimVersion,
        );
      } else {
        // This runs if feature flag is session based
        cachedValue = FeatureFlagsClient.cachedValues.get(flagName);
        // If there is no cached value, this is first read of the flag - Start of the session - we load current flag value
        if (cachedValue === undefined) {
          value = this.returnFlagByType(
            value,
            defaultValue,
            withPrefixFlag,
            flagContextWithVimVersion,
          );
          FeatureFlagsClient.cachedValues.set(flagName, value);
        } else {
          // If value exists - Middle of the session - returned cached flag value
          value = cachedValue;
        }
      }
      FeatureFlagsClient.log('debug', 'Feature Flags - Got flag value', {
        flagName: withPrefixFlag,
        flagValue: value,
        cachedValue,
        flagOptions: {
          namespace: this.namespace,
          team: this.team,
          ...flagContextWithVimVersion,
        },
      });
      return value;
    } catch (error) {
      this.logError(error, flagOptions);
      return flagOptions.defaultValue;
    }
  }

  private returnFlagByType(
    value: any,
    defaultValue: any,
    withPrefixFlag: string,
    flagContextWithVimVersion: any,
  ) {
    if (typeof defaultValue === 'boolean')
      return Rox.dynamicApi.isEnabled(withPrefixFlag, defaultValue, flagContextWithVimVersion);
    if (typeof defaultValue === 'string')
      return Rox.dynamicApi.value(withPrefixFlag, defaultValue, flagContextWithVimVersion);
    if (typeof defaultValue === 'number')
      return Rox.dynamicApi.getNumber(withPrefixFlag, defaultValue, flagContextWithVimVersion);
    return value;
  }

  private static getDeviceId(): string | undefined {
    try {
      return new URLSearchParams(window.location.search).get('deviceId') || undefined;
    } catch {
      return undefined;
    }
  }

  private static async shouldUseProxy(key: string) {
    try {
      if ((window.$vim_environment?.VIM_ff_proxy_disable as string)?.toLowerCase() === 'true') {
        this.log('info', 'not using rollout proxy due to vim environment variable.', {});
        return false;
      }

      if ((window.$vim_environment?.VIM_disable_rollout_ping as string)?.toLowerCase() === 'true') {
        this.log(
          'info',
          'using rollout proxy due to ping disabling in vim environment variable',
          {},
        );

        return true;
      }

      this.isRolloutAccessible = (await this.pingRollout(key)) ?? false;
      this.log('info', 'finished rollout ping', { result: this.isRolloutAccessible });
      return !this.isRolloutAccessible;
    } catch (error) {
      this.log('error', 'failed to ping rollout, using proxy as default strategy', {
        error,
      });
      return true;
    }
  }

  /**
   * Checks whether the domain of Rollout is accessible.
   */
  private static async pingRollout(key: string) {
    const controller = new AbortController();
    const timeout = setTimeout(() => {
      controller.abort();
    }, ROLLOUT_PING_TIMEOUT_IN_MS);
    try {
      const response = await fetch(`https://analytic.rollout.io/impression/${key}`, {
        method: 'OPTIONS',
        signal: controller.signal,
      }).catch((error) => {
        if (error?.name !== 'AbortError') {
          throw error;
        }
      });
      if (response === undefined) {
        return false;
      }
      const body = await response.text();
      return response.ok && body === 'test successful';
    } catch (error) {
      this.log('info', 'Rollout ping failed', { error });
      return false;
    } finally {
      clearTimeout(timeout);
    }
  }

  private static log(level: 'error' | 'info' | 'debug', message: string, data: any) {
    const deviceId = this.getDeviceId();
    if (deviceId) {
      ScopedLogger.setDeviceId(deviceId);
    }
    this.logger[level](message, { origin: window.location.origin, ...data });
  }
}
