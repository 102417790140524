import { Standard } from '@getvim/vim-connect';

type GetProviderAppointments =
  Standard.Actions.ActionsStandard[Standard.Actions.ActionNames.GET_PROVIDER_APPOINTMENTS];

export const getProviderAppointments: {
  input: GetProviderAppointments['input'];
} = {
  input: {
    ehrProviderId: '',
    date: new Date('2024-12-31'),
  },
};
