import { Standard } from '@getvim/vim-connect';

function createFieldTemplate(fieldName: string): string {
  return `$$$$$$$$ ${fieldName} $$$$$$$$`;
}

export function getUpdateSOAPPayload(): Standard.WriteBacks.UpdateEncounter {
  return {
    subjective: {
      generalNotes: createFieldTemplate('subjective FREE TEXT'),
      reviewOfSystemsNotes: createFieldTemplate('ROS FREE TEXT'),
      historyOfPresentIllnessNotes: createFieldTemplate('HPI FREE TEXT'),
      chiefComplaintNotes: createFieldTemplate('chiefComplaintNotes'),
    },
    objective: {
      generalNotes: createFieldTemplate('objective FREE TEXT'),
      physicalExamNotes: createFieldTemplate('PE FREE TEXT'),
    },
    assessments: { generalNotes: createFieldTemplate('assessments generalNotes') },
    plans: { generalNotes: createFieldTemplate('plans') },
    patientInstructions: { generalNotes: createFieldTemplate('patientInstructions') },
  };
}

export function getUpdateSOAPAssessmentGNPayload(): Standard.WriteBacks.UpdateEncounter {
  return { assessments: { generalNotes: createFieldTemplate('assessments generalNotes') } };
}

export function getUpdateSOAPObjectivePayload(): Standard.WriteBacks.UpdateEncounter {
  return {
    objective: {
      generalNotes: createFieldTemplate('objective FREE TEXT'),
      physicalExamNotes: createFieldTemplate('PE FREE TEXT'),
    },
  };
}

export function getUpdateSOAPSubjectivePayload(): Standard.WriteBacks.UpdateEncounter {
  return {
    subjective: {
      generalNotes: createFieldTemplate('subjective FREE TEXT'),
      reviewOfSystemsNotes: createFieldTemplate('ROS FREE TEXT'),
      historyOfPresentIllnessNotes: createFieldTemplate('HPI FREE TEXT'),
      chiefComplaintNotes: createFieldTemplate('chiefComplaintNotes'),
    },
  };
}

export function getUpdateSOAPPlanGNPayload(): Standard.WriteBacks.UpdateEncounter {
  return { plans: { generalNotes: createFieldTemplate('plans') } };
}

export function getUpdateSOAPPatientInstructionsGNPayload(): Standard.WriteBacks.UpdateEncounter {
  return { patientInstructions: { generalNotes: createFieldTemplate('patientInstructions') } };
}
